import type { ColumnSort } from './types';
import type { $TSFixMe } from '@readme/iso';
import type {
  ColumnDef,
  VisibilityState,
  ColumnOrderState,
  OnChangeFn,
  Row,
  Header,
  RowData,
} from '@tanstack/react-table';

import { flexRender, getCoreRowModel, useReactTable, getExpandedRowModel } from '@tanstack/react-table';
import React, { useState, useCallback, useMemo, useEffect, Fragment } from 'react';

import useClassy from '@core/hooks/useClassy';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Graphic from '@ui/Graphic';
import Icon from '@ui/Icon';
import ListPagination from '@ui/ListPagination';
import Spinner from '@ui/Spinner';

import { TextCell } from './components/Cells';
import ColumnSelector from './components/ColumnSelector';
import classes from './style.module.scss';

export const baseDefaultColumn: Partial<ColumnDef<RowData>> = {
  enableSorting: false,
  size: 100,
  cell: ({ getValue }) => <TextCell value={getValue<string>() || ''} />,
};

/**
 * Note: there are some type issues with the react-table types, so casting is required for select props (columns, defaultColumn, onRowClick)
 * @see: https://github.com/TanStack/table/issues/4382
 */
interface Props {
  className?: string;
  /** Column visibility and order options */
  columnOptions?: {
    order: ColumnOrderState;
    visibility: VisibilityState;
  };
  /**
   * Column definitions have several options
   * @see https://tanstack.com/table/v8/docs/api/core/column-def
   */
  columns: ColumnDef<RowData>[];
  /** Optional empty state component to render in place of default "No data found" when there are no results */
  customEmptyRender?: React.ReactNode;
  /** Core table data */
  data: RowData[];
  /** Default column definition */
  defaultColumn?: Partial<ColumnDef<RowData>>;
  getRowCanExpand?: () => boolean;
  hasError?: boolean;
  /** Whether to display the table headers or not */
  hideHeader?: boolean;
  /** Whether or not to hide pagination if you don't need it */
  hidePagination?: boolean;
  /** Whether to highlight the row on click */
  highlightRowOnClick?: boolean;
  /** Whether to include the column selector (allows user to hide and reorder columns) */
  includeColumnSelector?: boolean;
  /** Whether to render the table in a compact style (rows have smaller padding) */
  isCompact?: boolean;
  isExportLoading?: boolean;
  isLoading?: boolean;
  /** Table layout */
  layout?: 'auto' | 'fixed';
  /**
   * A catch-all option prop for passing in additional data to the table
   * Great for arbitrary data or functions to your table without having to pass it to every thing the table touches
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: Record<string, any>;
  onColumnOrderChange?: (order: ColumnOrderState) => void;
  onColumnVisibilityChange?: OnChangeFn<VisibilityState>;
  onExport?: () => void;
  onPageChange: (page: number) => void;
  onRowClick?: (row: $TSFixMe | RowData) => void;
  onSort: ({ column, direction }: ColumnSort) => void;
  /** Zero-indexed pagination */
  page: number;
  pages: number;
  renderSubComponent?: (row: Row<unknown>) => React.ReactNode;
  /** Whether to render the table inside a <Box> card w/ pagination below */
  showWithCard?: boolean;
  sort: ColumnSort;
  /** Whether to render the table with a transparent header */
  transparentHeader?: boolean;
}

const ReactTable = ({
  className,
  columnOptions,
  columns,
  customEmptyRender,
  data = [],
  defaultColumn = baseDefaultColumn,
  hasError,
  hideHeader = false,
  hidePagination = false,
  highlightRowOnClick,
  includeColumnSelector = false,
  isCompact = false,
  isExportLoading,
  isLoading,
  layout = 'fixed',
  meta,
  onColumnOrderChange,
  onColumnVisibilityChange,
  onExport,
  onPageChange,
  onRowClick,
  onSort,
  page,
  pages,
  showWithCard = false,
  sort,
  transparentHeader,
  renderSubComponent,
  getRowCanExpand,
}: Props) => {
  const bem = useClassy(classes, 'ReactTable');
  const [highlightedRow, setHighlightedRow] = useState('');

  useEffect(() => {
    if (!highlightRowOnClick) {
      setHighlightedRow('');
    }
  }, [highlightRowOnClick]);

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    state: {
      columnVisibility: columnOptions?.visibility || {},
      columnOrder: columnOptions?.order || [],
      sorting: [
        {
          id: sort.column,
          desc: sort.direction === 'desc',
        },
      ],
    },
    manualPagination: true,
    manualSorting: true,
    onColumnVisibilityChange,
    getCoreRowModel: getCoreRowModel(),
    meta,
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
    autoResetExpanded: true,
  });

  const handleHeaderClickOrKeyDown = useCallback(
    (
      event: React.KeyboardEvent<HTMLTableCellElement> | React.MouseEvent<HTMLTableCellElement, MouseEvent>,
      header: Header<unknown, unknown>,
    ) => {
      if (event.type === 'click' || (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Enter')) {
        if (header.column.getCanSort()) {
          onSort?.({ column: header.column.id, direction: sort.direction === 'desc' ? 'asc' : 'desc' });
        }
      }
    },
    [onSort, sort.direction],
  );

  const handleRowClickOrKeyDown = useCallback(
    (
      event: React.KeyboardEvent<HTMLTableRowElement> | React.MouseEvent<HTMLTableRowElement, MouseEvent>,
      row: Row<RowData>,
    ) => {
      if (event.type === 'click' || (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Enter')) {
        onRowClick?.(row);
        setHighlightedRow(row.id);
      }
    },
    [onRowClick],
  );

  const emptyState = useMemo(() => {
    if (hasError) {
      return (
        <Flex align="center" gap="xs" layout="col">
          <Graphic name="warning" size="lg" />
          Something went wrong!
        </Flex>
      );
    }

    return customEmptyRender || 'No data found';
  }, [customEmptyRender, hasError]);

  const showEmptyState = useMemo(() => !data || data?.length === 0 || hasError, [data, hasError]);

  const pagination = useMemo(() => {
    // Hide pagination for errors and empty states
    if (hidePagination || hasError || (!isLoading && showEmptyState)) return null;

    return (
      <ListPagination
        className={bem('-pagination', showWithCard && '-pagination-with-card')}
        loading={isLoading}
        onNext={() => onPageChange(page + 1)}
        onPage={ev => onPageChange(Number(ev.target.value - 1))}
        onPrevious={() => onPageChange(page - 1)}
        page={page + 1}
        pages={pages}
      >
        {!!onExport && (
          <Button disabled={isLoading || isExportLoading} kind="minimum" onClick={onExport} outline size="sm">
            <Icon name="download" /> Export CSV
          </Button>
        )}
      </ListPagination>
    );
  }, [
    hidePagination,
    hasError,
    isLoading,
    showEmptyState,
    bem,
    showWithCard,
    page,
    pages,
    onExport,
    isExportLoading,
    onPageChange,
  ]);

  const Tag = showWithCard ? Box : 'div';
  const showColumnSelector = !!includeColumnSelector && !!onColumnOrderChange;

  return (
    <>
      <Tag
        className={bem('&', showWithCard && '-table-with-card', isCompact && '-table-compact', className)}
        {...(showWithCard ? { kind: 'card' } : {})}
      >
        {isLoading ? (
          <div className={bem('-table-loading')}>
            <Spinner size="lg" />
          </div>
        ) : showEmptyState ? (
          <div className={bem('-table-empty')}>{emptyState}</div>
        ) : (
          <div className={bem('-table-wrapper')}>
            {!!data?.length && (
              <table className={bem('-table', `-table_${layout}`)}>
                {!hideHeader && (
                  <thead className={bem('-thead', transparentHeader && '-thead-transparent')}>
                    {table.getHeaderGroups().map(headerGroup => (
                      <tr key={headerGroup.id} className={bem('-tr')}>
                        {headerGroup.headers.map(header => (
                          <th
                            key={header.id}
                            className={bem('-th', header.column.getCanSort() && '-th-sort')}
                            colSpan={header.colSpan}
                            onClick={event => handleHeaderClickOrKeyDown(event, header)}
                            onKeyDown={event => handleHeaderClickOrKeyDown(event, header)}
                            style={{ width: header.getSize() }}
                            tabIndex={header.column.getCanSort() ? 0 : undefined}
                          >
                            {flexRender(
                              <Flex align="center" gap="xs" justify="start">
                                {header.column.columnDef.header}
                                {{
                                  asc: <Icon name="chevron-up" strokeWeight={3} />,
                                  desc: <Icon name="chevron-down" strokeWeight={3} />,
                                }[header.column.getIsSorted() as string] ?? null}
                              </Flex>,
                              header.getContext(),
                            )}
                          </th>
                        ))}

                        {!!showColumnSelector && (
                          <th className={bem('-th', '-th-columnSelector')}>
                            <ColumnSelector
                              allColumns={table.getAllLeafColumns()}
                              setColumnOrder={onColumnOrderChange}
                            />
                          </th>
                        )}
                      </tr>
                    ))}
                  </thead>
                )}

                <tbody>
                  {table.getRowModel().rows.map(row => (
                    <Fragment key={row.id}>
                      <tr
                        className={bem(
                          '-tr',
                          !!onRowClick && '-tr-clickable',
                          !!onRowClick && highlightRowOnClick && highlightedRow === row.id && '-tr-highlight',
                        )}
                        onClick={event => handleRowClickOrKeyDown(event, row)}
                        onKeyDown={event => handleRowClickOrKeyDown(event, row)}
                        tabIndex={onRowClick ? 0 : undefined}
                      >
                        {row.getVisibleCells().map((cell, index) => {
                          const columnDef = cell.column.columnDef;

                          // @ts-ignore - this is a bug in the react-table types
                          const allowOverflow = columnDef.meta?.enableOverflow;

                          // When column selector is enabled, we'll have the second to last <td> span two columns
                          const colSpan = showColumnSelector && index === row.getVisibleCells().length - 1 ? 2 : 1;

                          return (
                            <td key={cell.id} className={bem('-td', allowOverflow && '-td-overflow')} colSpan={colSpan}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>

                      {!!renderSubComponent && row.getIsExpanded() && (
                        <tr className={bem('-tr-sub-component', highlightedRow === row.id && '-tr-highlight')}>
                          {/* 2nd row is a custom 1 cell row */}
                          <td colSpan={row.getVisibleCells().length}>{renderSubComponent(row)}</td>
                        </tr>
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}

        {!showWithCard && pagination}
      </Tag>

      {!!showWithCard && pagination}
    </>
  );
};

export { default as useTablePreferences } from './useTablePreferences';
export default ReactTable;
