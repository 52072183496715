import copy from 'clipboard-copy';
import React, { useState, useEffect } from 'react';

import useClassy from '@core/hooks/useClassy';

import type { ButtonProps } from '@ui/Button';
import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

type Props = Omit<ButtonProps, 'text'> & {
  /**
   * Any additional classNames you wish to add to the button.
   */
  className?: string;

  /**
   * Callback function for handling successful clipboard usage.
   */
  onCopy?: () => void;

  /**
   * Callback function for handling failed attempts to copy data to the clipboard. This'll usually get thrown for older
   * browsers.
   */
  onCopyError?: (err: Error) => void;

  /**
   * Even though `shift` is unused here or within `attributes` it is an attribute specific to
   * our `Flex` component CSS so instead of having a generic Flex-specific `HTMLAttributes` interface
   * we're defining it here.
   *
   * @todo make a generic Flex `HTMLAttributes` interface that this component extends
   * @see {@link https://readmeio.slack.com/archives/C01E8CZ6ZG9/p1653351668673939}
   */
  shift?: string;

  /**
   * Text to add into the clipboard. Can be either a string or a function (if you need to inject dynamic data).
   */
  text: string | (() => string);
};

const CopyToClipboard = ({ className, onCopy = () => {}, onCopyError = () => {}, text, ...attributes }: Props) => {
  const bem = useClassy(classes, 'CopyToClipboard');

  const [isCopied, setIsCopied] = useState(false);
  const [tooltipText, setTooltipText] = useState('Copy');
  const timeoutDuration = 2000;

  const resetTooltip = () => {
    setIsCopied(false);
    setTooltipText('Copy');
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (isCopied) {
      timer = setTimeout(() => {
        resetTooltip();
      }, timeoutDuration);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isCopied]);

  const handleCopy = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const textToCopy = typeof text === 'function' ? text() : text;

    copy(textToCopy)
      .then(() => {
        setIsCopied(true);
        setTooltipText('Copied');
        onCopy();
      })
      .catch(err => {
        setTooltipText('Unable to copy');
        onCopyError(err);
      });
  };

  return (
    <Tooltip content={tooltipText} hideOnClick={false} onHidden={() => resetTooltip()} placement="bottom">
      <Button
        aria-label="Copy to clipboard"
        className={bem('&', className)}
        kind="secondary"
        onClick={handleCopy}
        onKeyPress={e => {
          if (e.key === ' ' || e.key === 'Enter') {
            handleCopy(e);
          }
        }}
        size="sm"
        text
        {...attributes}
      >
        <Icon name={isCopied ? 'check' : 'copy'} />
      </Button>
    </Tooltip>
  );
};

export default React.memo(CopyToClipboard);
